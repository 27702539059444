import React, { useEffect, useState } from "react";
import Footer from "../layout/Footer";
import OwlCarousel from "react-owl-carousel";
import quote from "../../src/assets/img/straight-quotes.png";


import people from "../../src/assets/img/people.png";
import quality from "../../src/assets/img/quaity.png";
import innovation from "../../src/assets/img/innovation.png";

import shapeimg from "../../src/assets/img/G2.png";
import Header from "../layout/Header";
import Partners from "../Component/Partner";
import { APICALL, imgBaseURL } from "../utils/api";
import HTMLContent from "../Component/HTMLContent";
import { Link } from "react-router-dom";
import WhatWeOffer from "../Component/WhatWeOffer";
import Testimonials from "../Component/Testimonials";
const Home = ({ rootData }) => {
  const heroslider = {
    loop: true,
    margin: 0,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 8000,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 1, // Show 1 item for screens 0px and up
      },
      600: {
        items: 1, // Show 2 items for screens 600px and up
      },
      1000: {
        items: 1, // Show 4 items for screens 1000px and up
      },
    },
  };

  const testimonial = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayTimeout: 5000,
    smartSpeed: 700,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };
  const aboutslider = {
    items: 1,
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: false,
    autoplayTimeout: 5000,
    smartSpeed: 700,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };

  const [getHomeContent, setHomeContent] = useState(null)
  const [activeTab, setActiveTab] = useState();
  const [activeContent, setActiveContent] = useState();

  useEffect(() => {
    getDataFun()
  }, [])

  const getDataFun = async () => {
    try {
      const res = await APICALL(`/get-data/home`)
      if (res?.status) {
        setHomeContent(res?.data)
        setActiveTab(res?.data?.home_about?.aboutTab[0].tab)
        setActiveContent(res?.data?.home_about?.aboutTab[0])
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className="margindiv-header">
        {
          getHomeContent && <>
            <section className="hero-sec">
              <OwlCarousel className="owl-theme" {...heroslider} >
                {
                  getHomeContent?.banner?.map((banner, i) => (
                    <div className="item" style={{ backgroundImage: `url(${imgBaseURL + banner?.image})`, }} >
                      <div className="container" data-aos="fade-up">
                        <div className="welcomesolution"> {banner?.sub_title} </div>
                        <h1>
                          {banner?.title ? (
                            <>
                              {banner?.title?.split(" ")[0]}
                              <span> {banner?.title.substring(banner?.title?.indexOf(" ") + 1)} </span>
                            </>
                          ) : (
                            <>{banner?.title}</>
                          )}
                        </h1>
                        <ul>
                          <li>
                            <img src={people} alt="" /> People
                          </li>
                          <li><img src={quality} alt="" />Quality</li>
                          <li><img src={innovation} alt="" /> Innovation </li>
                        </ul>
                        <Link to="/product" className="btn">Our Services
                          <i aria-hidden="true" className="fa fa-arrow-right"></i>
                        </Link>
                      </div>
                    </div>
                  ))

                }
              </OwlCarousel>
            </section>
          </>
        }

        <section className="about-quesolution">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5 pe-md-4 mb-md-0 mb-4">
                <img
                  data-aos="fade-up"
                  src={imgBaseURL + getHomeContent?.cue_solutions?.image}
                  alt="Graviton Since 1996"
                  className="img-fluid"
                />
              </div>
              <div className="col-md-7">
                <div className="section-title" data-aos="fade-left">
                  <span className="main_header_tittle">Cue Solutions​</span>
                  <h2>
                    {getHomeContent?.cue_solutions?.title}
                  </h2>
                  <p className="mb-xl-4">
                    <HTMLContent data={getHomeContent?.cue_solutions?.text} />
                  </p>
                  {
                    getHomeContent &&
                    <div className="orl-casosel-inner">
                      <OwlCarousel className="owl-theme" {...aboutslider}>
                        {
                          Array.isArray(JSON.parse(getHomeContent?.cue_solutions?.skills)) &&
                          JSON.parse(getHomeContent.cue_solutions.skills)?.map((item, i) => (
                            <div className="about-owlslider" key={i}>
                              <p>{item}</p>
                              <h3>Our Skill</h3>
                            </div>
                          ))
                        }
                      </OwlCarousel>
                    </div>
                  }
                  <Link to="/product" className="btn mt-md-5 mt-4">
                    Our Services
                    <i aria-hidden="true" className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <WhatWeOffer data={getHomeContent?.whatWeOffer} />

        <section className="about-section">
          <img src={shapeimg} className="rightimg-about" alt="" />
          <img src={shapeimg} alt="" className="lefttimg-about" />
          <div className="container">
            <div className="row">
              <div className="col-xl-5  col-lg-6  mb-lg-5 mb-4 section-title" data-aos="fade-right">
                <span className="main_header_tittle">About Us</span>
                <h2>{getHomeContent?.home_about?.main?.title}</h2>
              </div>
            </div>
          </div>
          {
            getHomeContent &&
            <div className="row align-items-center">
              <div className="col-lg-7  pe-lg-5 mb-lg-0 mb-4">
                <img src={imgBaseURL + activeContent?.image} alt={activeContent?.tab} className="img-fluid" />
              </div>
              <div className="col-lg-5">
                <div className="tabs mb-lg-5 mb-4">
                  {
                    getHomeContent &&
                    getHomeContent?.home_about?.aboutTab.map((tabData) => (
                      <button
                        key={tabData.tab}
                        className={`tab-button ${activeTab === tabData.tab ? "active" : ""}`}
                        onClick={() => { setActiveTab(tabData.tab); setActiveContent(tabData) }}
                      >
                        {tabData.tab}
                      </button>
                    ))
                  }
                </div>
                <div className="section-title innerabout-tab">
                  <h2>{activeContent?.title}</h2>
                  <p>{activeContent?.description}</p>
                  <Link to="/about" className="btn">
                    Learn More <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          }
        </section>

        <Partners data={getHomeContent?.logos} />
        <Testimonials data={getHomeContent?.testimonials} />
      </div>
    </>
  );
};

export default Home;
